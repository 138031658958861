<template>
  <Link :href="`/advisor/${advisor.url}`" class="block link text-black no-underline h-full">
    <se-card v-if="advisor" class="px-16 pb-16 pt-24 h-full flex flex-col transition hover:shadow-large">
      <div class="flex pb-16">
        <se-avatar :id="advisor.id" :name="advisor.name" :src="advisor.avatar" :size="90" class="!rounded-12 !min-w-88" />
        <div class="pl-16 flex flex-col flex-grow-1">
          <h3 class="text-h-m font-bold !mb-8 line-clamp-1">
            {{ advisor.name }}
          </h3>
          <div class="flex pb-8">
            <se-advisor-status :advisor="props.advisor" />
          </div>
          <se-advisor-accuracy :advisor="props.advisor" />
        </div>
      </div>
      <div class="text-h-m text-neutral-80 pb-16">
        <span v-if="advisor.short_description">{{ advisor.short_description }}</span>
        <span v-else>This advisor doesn’t have any description yet</span>
      </div>
      <div class="flex-grow" />
      <div class="bg-neutral-10 rounded-8 flex items-center justify-between p-12 mb-16">
        <div class="flex items-center gap-16">
          <div>
            <se-icon name="video" :size="24" :class="advisor.video_enabled && isOnline ? 'text-primary-100' : 'text-neutral-50'" />
          </div>
          <div>
            <se-icon name="phone" :size="20" :class="advisor.audio_enabled && isOnline ? 'text-feature-100' : 'text-neutral-50'" />
          </div>
          <div>
            <se-icon name="message-circle" :size="20" :class="advisor.text_enabled && isOnline ? 'text-love-100' : 'text-neutral-50'" />
          </div>
        </div>
        <div class="flex justify-right items-center">
          <se-advisor-general-price :advisor="advisor" />
        </div>
      </div>
      <div v-if="isOnline">
        <se-button class="w-full">
          <span v-if="advisor.free_minutes_left">Start {{ advisor.free_minutes_left }} min Free Chat</span>
          <span v-else>Start Session</span>
          <span v-if="advisor.discount">&nbsp;&nbsp;•&nbsp;&nbsp;{{ advisor.discount }}% OFF</span>
        </se-button>
      </div>
      <div v-else class="flex items-center gap-8">
        <se-advisor-offline-options :advisor="props.advisor" />
      </div>
    </se-card>
  </Link>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import SeAvatar from '../../../shared/ui-kit/avatar/Avatar.vue';
import SeButton from '../../../shared/ui-kit/button/Button.vue';
import SeCard from '../../../shared/ui-kit/card/Card.vue';
import SeIcon from '../../../shared/ui-kit/icon/Icon.vue';
import SeAdvisorStatus from './shared/advisor-status.vue';
import SeAdvisorGeneralPrice from './shared/advisor-general-price.vue';
import Link from '../../../components/Link.vue';
import SeAdvisorAccuracy from './shared/advisor-accuracy.vue';
import SeAdvisorOfflineOptions from './shared/advisor-offline-options.vue';

const props = defineProps<{
  advisor: any
}>();

const isOnline = computed(() => props.advisor.status === 'online');
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorCard',
};
</script>
